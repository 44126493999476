import React from "react";
import Layout from "../../components/Layout";

export default function materialDeEducacionInicial() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_educativo/13.jpg)`}}
                    >
                        <h1>Material de Educación Incial</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Productos y servicios para Formación, capacitación y
                            actualización docente para el
                            (PEEI)</h1>
                        <p className="services_section_1__paragraph">
                            Programa de Expansión de la Educación Inicial
                        </p>
                        <p className="services_section_1__paragraph">
                            <strong>Su objetivo:</strong> Capacitar agentes de educación inicial para la implementación
                            del Programa de
                            Educación Inicial: Un buen comienzo y el uso de materiales pedagógicos y didácticos.
                        </p>
                        <p className="services_section_1__paragraph">
                            <strong>Dirigido a:</strong> Agentes educativos y comunitarios
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}